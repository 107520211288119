<template lang="pug">
footer.footer
  .footer__cont.cont
    span.footer__logo(v-if="isHomePage")
      img.footer__logo-image(src="/images/logo.svg", alt="Логотип Музея «Шоколадный Выборг»", title="Логотип Музея «Шоколадный Выборг»", loading="lazy", width="160", height="160")
    router-link.footer__logo(v-else, :to="{ name: 'Home' }")
      img.footer__logo-image(src="/images/logo.svg", alt="Логотип Музея «Шоколадный Выборг»", title="Логотип Музея «Шоколадный Выборг»", loading="lazy", width="160", height="160")
    .footer__wrapper
      .footer__box(v-for="(item, i) in footerItems", :key="i")
        span.footer__title {{item.title}}
        ul.footer__list
          li.footer__item(v-for="(link, i) in item.links", :key="i", :class="{'footer__item_sign' : link.sign, 'footer__item_grot' : link.page === 'Grot'}")
            router-link.footer__link.text(:to="{ name: link.page }", active-class="footer__link_active") {{ link.value }}
</template>

<script>
import '@/assets/styles/components/footer.sass'

export default {
  name: 'TheFooter',
  data () {
    return {
      footerItems: [
        {
          title: 'Магазин',
          links: [
            { page: 'Catalog', value: 'Каталог', sign: true },
            { page: 'OnOrder', value: 'Корпоративные подарки' }
          ]
        }, {
          title: 'Покупателям',
          links: [
            { page: 'Delivery', value: 'Доставка' },
            { page: 'Payment', value: 'Оплата' }
          ]
        }, {
          title: 'Мероприятия',
          links: [
            { page: 'Excursion', value: 'Экскурсии', sign: true },
            { page: 'ExcursionWithDegustation', value: 'Экскурсии с дегустацией', sign: true },
            { page: 'Master', value: 'Мастер-классы' },
            { page: 'Degustations', value: 'Дегустации' },
            { page: 'Birthdays', value: 'Дни рождения' },
            { page: 'Quest', value: 'Квест' },
            { page: 'Grot', value: 'Арт-пространство «Грот»' }
          ]
        }, {
          title: 'Компания',
          links: [
            { page: 'About', value: 'О Музее «Шоколадный Выборг»' },
            { page: 'Contacts', value: 'Контакты' },
            { page: 'Requisites', value: 'Реквизиты' },
            { page: 'Franchise', value: 'Франшиза', sign: true },
            { page: 'Policy', value: 'Политика' }
          ]
        }
      ],
      isHomePage: ''
    }
  },
  watch: {
    '$route.name' () {
      this.isHomePage = this.$route.name === 'Home'
    }
  }
}
</script>
